import React, { useState, useEffect, useContext, useMemo } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import Header from '../../../layout/Header/Header';
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  Label,
  ButtonGroup,
  Col,
} from 'reactstrap';
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import ExportJsonExcel from 'js-export-excel';
import { encode } from 'base64-arraybuffer';
import axios from 'axios';
import { TableHeader, Pagination, Search } from '../../../DataTable';
import useFullPageLoader from '../../../hooks/useFullPageLoader';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import EtaUpdate from '../../EtaUpdate';
import Baja from '../../Baja';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function ListadoContenedores() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_CONTENEDORES = process.env.REACT_APP_URL_CONTENEDORES;
  const URL_ARTICULOS_CONTENEDOR = process.env.REACT_APP_URL_ARTICULOS_CONTENEDOR;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_GASTOS_CONTENEDOR = process.env.REACT_APP_URL_GASTOS_CONTENEDOR;
  const URL_CARGOS_CONTENEDOR = process.env.REACT_APP_URL_CARGOS_CONTENEDOR;
  const URL_CONCEPTOS_GASTOS = process.env.REACT_APP_URL_CONCEPTOS_GASTOS;
  const URL_TERMINALES = process.env.REACT_APP_URL_TERMINALES;
  const URL_PROGRAMAS = process.env.REACT_APP_URL_PROGRAMAS;
  const URL_PATENTES = process.env.REACT_APP_URL_PATENTES;

  const [t, i18n] = useTranslation('global');
  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 365);

  let comp30 = new Date();
  comp30.setDate(comp30.getDate() - 30);

  let endDate = moment(hoy).format('YYYY-MM-DD');
  let startDate = moment(inicio).format('YYYY-MM-DD');

  const [articulos, setArticulos] = useState([]);

  const [programas, setProgramas] = useState([]);
  const [patentes, setPatentes] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [terminales, setTerminales] = useState([]);
  const [selectedNaviera, setSelectedNaviera] = useState('');
  const [selectedTerminal, setSelectedTerminal] = useState('');
  const [selectedPrograma, setSelectedPrograma] = useState('');
  const [selectedPatente, setSelectedPatente] = useState('');
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState('');
  const [selectedAgente, setSelectedAgente] = useState('');
  const [selectedForwarder, setSelectedForwarder] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedPuerto, setSelectedPuerto] = useState('');
  const [selectedModalidad, setSelectedModalidad] = useState('');
  const [selectedAsegurado, setSelectedAsegurado] = useState('');
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [selectedPendiente, setSelectedPendiente] = useState('No');
  const [selectedActivo, setSelectedActivo] = useState('Si');

  const [fecha, setFecha] = useState('');
  const [puerto, setPuerto] = useState('');
  const [direccionEntrega, setDireccionEntrega] = useState('');
  const [observaciones, setObservaciones] = useState('NA');
  const [observacionesInternas, setObservacionesInternas] = useState('NA');
  const [totalGeneral, setTotalGeneral] = useState('');
  const [totalCantidad, setTotalCantidad] = useState('');
  const [numeroContenedor, setNumeroContenedor] = useState('');
  const [rastreo, setRastreo] = useState('No');

  const [idEdit, setIdEdit] = useState('');
  const [selectedNavieraEdit, setSelectedNavieraEdit] = useState('');
  const [selectedClienteEdit, setSelectedClienteEdit] = useState('');
  const [selectedAgenteEdit, setSelectedAgenteEdit] = useState('');
  const [selectedForwarderEdit, setSelectedForwarderEdit] = useState('');

  const [coordenadasMapa, setCoordenadasMapa] = useState([]);
  const [originCoords, setOriginCoords] = useState([]);
  const [destinationCoords, setDestinationCoords] = useState([]);
  const [vesselCoords, setVesselCoords] = useState([]);
  const [diasLlegada, setDiasLlegada] = useState(0);

  const [modalMapa, setModalMapa] = useState(false);
  const toggleMapa = () => setModalMapa(!modalMapa);

  const [numeroInterno, setnumeroInterno] = useState('');
  const [contenedor, setContenedor] = useState('');
  // const [fechaETAEstimada, setFechaETAEstimada] = useState("");
  const [fechaETAReal, setFechaETAReal] = useState('');

  const [articulosContenedor, setArticulosContenedor] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [totalGeneral_edit, setTotalGeneralEdit] = useState(0);
  const [totalCantidad_edit, setTotalCantidadEdit] = useState(0);

  const [validaBoton, setValidaBoton] = useState(true);
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: '',
      cantidad: 0,
      precio: 0,
      total: 0,
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState('');

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalETA, setModalETA] = useState(false);
  const toggleETA = () => setModalETA(!modalETA);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [modalStatus, setModalStatus] = useState(false);
  const toggleStatus = () => setModalStatus(!modalStatus);
  const [id, setid] = useState('');
  const [bl, setbl] = useState('');
  const [pl, setpl] = useState('');
  const [pedimento, setPedimento] = useState('');
  const [factura, setfactura] = useState('');
  const [modalidad, setModalidad] = useState('');
  const [asegurado, setAsegurado] = useState('');
  const [documentos, setdocumentos] = useState('');
  const [telex, settelex] = useState('');
  const [toquePiso, settoquePiso] = useState('');
  const [revalidacion, setrevalidacion] = useState('');
  const [fichaTecnica, setfichaTecnica] = useState('');
  const [pagoPedimento, setpagoPedimento] = useState('');
  const [modulacion, setmodulacion] = useState('');
  const [retornoVacio, setretornoVacio] = useState('');
  const [salidaPuerto, setsalidaPuerto] = useState('');
  const [salidaRuta, setsalidaRuta] = useState('');
  const [entregaCliente, setentregaCliente] = useState('');
  const [maniobras, setmaniobras] = useState('');
  const [status, setstatus] = useState('');
  const [fechaEntradaPuerto, setfechaEntradaPuerto] = useState('');
  const [fechaDocumentos, setfechaDocumentos] = useState('');
  const [fechaTelex, setfechaTelex] = useState('');
  const [fechaToquePiso, setfechaToquePiso] = useState('');
  const [fechaRevalidacion, setfechaRevalidacion] = useState('');
  const [fechaFichaTecnica, setfechaFichaTecnica] = useState('');
  const [fechaPagoPedimento, setfechaPagoPedimento] = useState('');
  const [fechaModulacion, setfechaModulacion] = useState('');
  const [fechaRetornoVacio, setfechaRetornoVacio] = useState('');
  const [fechaSalidaPuerto, setfechaSalidaPuerto] = useState('');
  const [fechaSalidaRuta, setfechaSalidaRuta] = useState('');
  const [fechaEntregaCliente, setfechaEntregaCliente] = useState('');
  const [fechaManiobras, setfechaManiobras] = useState('');
  const [fechaFactura, setfechaFactura] = useState('');
  const [fechaBl, setfechaBl] = useState('');
  const [fechaPl, setfechaPl] = useState('');

  const [modalGastos, setModalGastos] = useState(false);
  const toggleGastos = () => setModalGastos(!modalGastos);
  const [fechaGasto, setFechaGasto] = useState(endDate);
  const [totalUSD, setTotalUSD] = useState(0);
  const [tipoCambio, setTipoCambio] = useState(1);
  const [total, setTotal] = useState(0);
  const [descripcion, setDescripcion] = useState('NA');
  const [conceptosGastos, setConceptosGastos] = useState([]);
  const [selectedConcepto, setSelectedConcepto] = useState('');
  const [selectedProveedor, setSelectedProveedor] = useState('');
  const [selectedContenedor, setSelectedContenedor] = useState('');
  const [selectedClienteGasto, setSelectedClienteGasto] = useState('');
  const [moneda, setMoneda] = useState('MXN');

  const [modalCargos, setModalCargos] = useState(false);
  const toggleCargos = () => setModalCargos(!modalCargos);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(
        `${URL_CONTENEDORES}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
          },
        }
      )
      .then((response) => {
        let allContenedores = response.data;
        // Array para pagination
        let arrayTabla = allContenedores
          .sort((a, b) => (a.etaReal > b.etaReal ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idContenedor,
              activo: a.is_active,
              fecha: a.fecha,
              observaciones: a.observaciones,
              observacionesInternas: a.observacionesInternas,
              totalGeneral: a.totalGeneral,
              navieras: a.navieras[0].name,
              idNavieras: a.navieras[0]._id,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              agentes: a.agentes[0].name,
              idAgentes: a.agentes[0]._id,
              terminales: a.terminales[0].name,
              idTerminales: a.terminales[0]._id,
              programas: a.programas[0].name,
              idProgramas: a.programas[0]._id,
              patentes: a.patentes[0].name,
              idPatentes: a.patentes[0]._id,
              forwarders: a.forwarders[0].name,
              idForwarders: a.forwarders[0]._id,
              totalCantidad: a.totalCantidad,
              numeroInterno:
                a.clientes[0].nombre_comercial + '-' + a.idContenedor,
              contenedor: a.contenedor,
              // etaEstimada: a.etaEstimada,
              etaReal: a.etaReal,
              bl: a.bl,
              pl: a.pl,
              totalGastos: a.totalGastos,
              totalCargos: a.totalCargos,
              puerto: a.puerto,
              direccionEntrega: a.direccionEntrega,
              totalCargos: a.totalCargos,

              pedimento: a.pedimento,
              modalidad: a.modalidad,

              asegurado: a.asegurado,

              factura: a.factura,
              documentos: a.documentos,
              telex: a.telex,
              toquePiso: a.toquePiso,
              revalidacion: a.revalidacion,
              fichaTecnica: a.fichaTecnica,
              pagoPedimento: a.pagoPedimento,
              modulacion: a.modulacion,
              retornoVacio: a.retornoVacio,
              salidaPuerto: a.salidaPuerto,
              salidaRuta: a.salidaRuta,
              entregaCliente: a.entregaCliente,
              maniobras: a.maniobras,
              status: a.maniobras == "Si" ? a.status + " " + a.fechaManiobras : a.status,
              fechaEntradaPuerto: a.fechaEntradaPuerto,
              fechaDocumentos: a.fechaDocumentos,
              fechaTelex: a.fechaTelex,
              fechaToquePiso: a.fechaToquePiso,
              fechaRevalidacion: a.fechaRevalidacion,
              fechaFichaTecnica: a.fechaFichaTecnica,
              fechaPagoPedimento: a.fechaPagoPedimento,
              fechaModulacion: a.fechaModulacion,
              fechaRetornoVacio: a.fechaRetornoVacio,
              fechaSalidaPuerto: a.fechaSalidaPuerto,
              fechaSalidaRuta: a.fechaSalidaRuta,
              fechaEntregaCliente: a.fechaEntregaCliente,
              fechaManiobras: a.fechaManiobras,
              fechaFactura: a.fechaFactura,
              fechaBl: a.fechaBl,
              fechaPl: a.fechaPl,

              rastreo:a.rastreo,
              mapa:a.mapa,
              fromCountry: a.fromCountry,
              polOrigen: a.polOrigen,
              loadingDate: a.loadingDate,
              departureDate: a.departureDate,
              toCountry: a.toCountry,
              podDestino: a.podDestino,
              arrivalDate: a.arrivalDate,
              dischargeDate: a.dischargeDate,
              vessel: a.vessel,
              vesselIMO: a.vesselIMO,
              vesselLatitude: a.vesselLatitude,
              vesselLongitude: a.vesselLongitude,
              vesselVoyage: a.vesselVoyage,
              polLatitude: a.polLatitude,
              polLongitude: a.polLongitude,
              podLatitude: a.podLatitude,
              podLongitude: a.podLongitude,
              vesselLatitude: a.vesselLatitude,
              vesselLongitude: a.vesselLongitude,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CLIENTES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_TERMINALES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      })
      .then((response) => {
        let allTerminales = response.data;
        setTerminales(allTerminales);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_PROVEEDORES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_PROGRAMAS}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      })
      .then((res) => {
        const allProgramas = res.data;
        setProgramas(allProgramas);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_PATENTES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      })
      .then((res) => {
        const allPatentes = res.data;
        setPatentes(allPatentes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CONCEPTOS_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedNaviera == 0 || selectedNaviera == a.idNavieras) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedForwarder == 0 || selectedForwarder == a.idForwarders) &&
        (selectedStatus == 0 || selectedStatus == a.status) &&
        (selectedPuerto == 0 || selectedPuerto == a.puerto) &&
        (selectedModalidad == 0 || selectedModalidad == a.selectedModalidad) &&
        (selectedAsegurado == 0 || selectedAsegurado == a.selectedAsegurado) &&
        (selectedAgente == 0 || selectedAgente == a.idAgentes) &&
        (selectedPendiente == 0 || selectedPendiente == a.entregaCliente) &&
        (selectedActivo == 0 || selectedActivo == a.activo)
      ) {
        return [
          a.etaReal,
          a.numeroInterno,
          a.contenedor,
          a.navieras,
          a.clientes,
          a.forwarders,
          a.puerto,
          a.modalidad,
          a.fechaModulacion,
          a.fechaEntregaCliente,
          a.status,
          a.observaciones,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF('landscape');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + 'Logo-Sistemify-PDF.png';
    doc.addImage(img, 'png', 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + 'logoInstitucion.png';
    doc.addImage(img2, 'png', 120, 15, 65, 28);
    doc.text(`Listado Contenedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          'ETA',
          'No. Interno',
          'Contenedor',
          'Naviera',
          'Cliente',
          'Forwarder',
          'Puerto',
          'Modalidad',
          'Modulacion',
          'Entrega Cliente',
          'Status',
          'Observaciones',
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Contenedores.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedNaviera == 0 || selectedNaviera == a.idNavieras) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedForwarder == 0 || selectedForwarder == a.idForwarders) &&
        (selectedStatus == 0 || selectedStatus == a.status) &&
        (selectedPuerto == 0 || selectedPuerto == a.puerto) &&
        (selectedModalidad == 0 || selectedModalidad == a.selectedModalidad) &&
        (selectedAsegurado == 0 || selectedAsegurado == a.selectedAsegurado) &&
        (selectedAgente == 0 || selectedAgente == a.idAgentes) &&
        (selectedPendiente == 0 || selectedPendiente == a.entregaCliente) &&
        (selectedActivo == 0 || selectedActivo == a.activo)
      ) {
        return {
          ID: a._id,
          Numero: a.numero,
          ETA: a.etaReal,
          NumeroInterno: a.numeroInterno,
          Contenedor: a.contenedor,
          Naviera: a.navieras,
          Cliente: a.clientes,
          AgenteAduanal: a.agentes,
          Forwarder: a.forwarders,
          Puerto: a.puerto,
          Modalidad: a.modalidad,
          Asegurado: a.asegurado,
          Modulacion: a.fechaModulacion,
          Status: a.status,
          Maniobras: a.fechaManiobras,
          Patente: a.patente,
          EntregaCliente: a.fechaEntregaCliente,
          Observaciones: a.observaciones,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = 'ListadoContenedores';

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: 'ListadoContenedores',
        sheetFilter: [
          'ID',
          'Numero',
          'ETA',
          'NumeroInterno',
          'Contenedor',
          'Naviera',
          'Cliente',
          'AgenteAduanal',
          'Forwarder',
          'Puerto',
          'Modalidad',
          'Asegurado',
          'Modulacion',
          'Status',
          'Maniobras',
          'Patente',
          'EntregaCliente',
          'Observaciones',
        ],
        sheetHeader: [
          'ID',
          'Numero',
          'ETA',
          'NumeroInterno',
          'Contenedor',
          'Naviera',
          'Cliente',
          'AgenteAduanal',
          'Forwarder',
          'Puerto',
          'Modalidad',
          'Asegurado',
          'Modulacion',
          'Status',
          'Maniobras',
          'Patente',
          'EntregaCliente',
          'Observaciones',
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedNaviera == 0 || selectedNaviera == a.idNavieras) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedForwarder == 0 || selectedForwarder == a.idForwarders) &&
        (selectedStatus == 0 || selectedStatus == a.status) &&
        (selectedPuerto == 0 || selectedPuerto == a.puerto) &&
        (selectedModalidad == 0 || selectedModalidad == a.selectedModalidad) &&
        (selectedAsegurado == 0 || selectedAsegurado == a.selectedAsegurado) &&
        (selectedAgente == 0 || selectedAgente == a.idAgentes) &&
        (selectedPendiente == 0 || selectedPendiente == a.entregaCliente) &&
        (selectedActivo == 0 || selectedActivo == a.activo)
      ) {
        return [
          a.numero,
          a.fecha,
          a.etaReal,
          a.numeroInterno,
          a.contenedor,
          a.navieras,
          a.clientes,
          a.agentes,
          a.forwarders,
          a.puerto,
          a.terminal,
          a.modalidad,
          a.fechaSalidaPuerto,
          a.fechaSalidaRuta,
          a.status,
          a.observaciones,
          a.programa,
          a.totalCantidad,
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          }).format(a.totalGeneral),
          a.direccionEntrega,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF('landscape');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + 'Logo-Sistemify-PDF.png';
    doc.addImage(img, 'png', 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + 'logoInstitucion.png';
    doc.addImage(img2, 'png', 120, 15, 65, 28);
    doc.text(`Listado Contenedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          'Numero',
          'Fecha',
          'ETA',
          'No. Interno',
          'Contenedor',
          'Naviera',
          'Cliente',
          'Agente Aduanal',
          'Forwarder',
          'Puerto',
          'Terminal',
          'Modalidad',
          'Salida Puerto',
          'Salida Ruta',
          'Status',
          'Observaciones',
          'Programa',
          'Cantidad',
          'Total',
          'Direccion Entrega',
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output('arraybuffer');
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: 'Listado Contenedores',
          email: mailTo,
          fileName: 'ListadoContenedores.pdf',
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Contenedores.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire('Good job!', 'Enviado con exito', 'success');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    idNavieras,
    idClientes,
    idAgentes,
    idForwarders,
    totalCantidad,
    totalGeneral,
    observaciones,
    observacionesInternas,
    numeroContenedor,
    numeroInterno,
    contenedor,
    // etaEstimada,
    etaReal,
    puerto,
    idTerminales,
    pedimento,
    modalidad,
    asegurado,
    direccionEntrega,
    idProgramas,
    idPatentes,
    rastreo,
    idEdit
  ) {
    setFecha(fecha);
    setSelectedNavieraEdit(idNavieras);
    setSelectedClienteEdit(idClientes);
    setSelectedAgenteEdit(idAgentes);
    setSelectedForwarderEdit(idForwarders);
    setTotalCantidad(totalCantidad);
    setTotalGeneral(totalGeneral);
    setObservaciones(observaciones);
    setObservacionesInternas(observacionesInternas);
    setNumeroContenedor(numeroContenedor);
    setnumeroInterno(numeroInterno);
    setContenedor(contenedor);
    // setFechaETAEstimada(etaEstimada);
    setFechaETAReal(etaReal);
    setPuerto(puerto);
    setSelectedTerminal(idTerminales);
    setPedimento(pedimento);
    setModalidad(modalidad);
    setAsegurado(asegurado);
    setDireccionEntrega(direccionEntrega);
    setSelectedPrograma(idProgramas);
    setSelectedPatente(idPatentes);
    setRastreo(rastreo);
    setIdEdit(idEdit);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    idNavieras,
    navieras,
    clientes,
    agentes,
    totalCantidad,
    totalGeneral,
    observaciones,
    numero,
    idPDFOC
  ) {
    await axios
      .get(`${URL_ARTICULOS_CONTENEDOR}/contenedor/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      })
      .then((response) => {
        let allArticulosContenedor = response.data;
        let arrayTabla = allArticulosContenedor
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].name,
              descripcion: a.descripcion,
              cantidad: a.cantidad,
              precio: a.precio,
              total: a.total,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let cant = inputFields.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidad(TC);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);

        const data = dataFinal.map((a) => {
          return [
            a.articulos,
            a.descripcion,
            new Intl.NumberFormat('en-US').format(a.cantidad),
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
            }).format(a.precio),
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
            }).format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF('landscape');
        var img = new Image();
        img.src = process.env.PUBLIC_URL + 'Logo-Sistemify-PDF.png';
        doc.addImage(img, 'png', 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + 'logoInstitucion.png';
        doc.addImage(img2, 'png', 120, 15, 45, 15);
        doc.text(`Contenedor # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Naviera ${navieras}`, 20, 30);
        doc.text(
          `Piezas ${new Intl.NumberFormat('en-US').format(totalCantidad)}`,
          20,
          40
        );
        doc.autoTable({
          head: [['Articulo', 'Descripcion', 'Cantidad', 'Precio', 'Total']],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [
              '',
              '',
              '',
              'Total',
              new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 2,
              }).format(totalGeneral),
            ],
          ],
          showFoot: 'lastPage',
        });
        doc.save(`Contenedor-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editContenedores(event) {
    event.preventDefault();
    const URL_CONTENEDORES_EDIT = `${process.env.REACT_APP_URL_CONTENEDORES}/${idEdit}`;

    axios
      .patch(
        URL_CONTENEDORES_EDIT,
        {
          fecha,
          navieras: selectedNavieraEdit,
          clientes: selectedClienteEdit,
          agentes: selectedAgenteEdit,
          forwarders: selectedForwarderEdit,
          observaciones,
          observacionesInternas,
          numeroInterno,
          contenedor,
          // etaEstimada: fechaETAEstimada,
          etaReal: fechaETAReal,
          puerto,
          terminales: selectedTerminal,
          pedimento,
          modalidad,
          asegurado,
          direccionEntrega,
          programas: selectedPrograma,
          patentes: selectedPatente,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: 'Editar Contenedor',
            detalle: `${numeroContenedor} ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
            },
          }
        ).then(() => {
          toggleEdit();
          Swal.fire('Good job!', 'Actualizado con exito', 'success');
          setTimeout(() => {
            // window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });


      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    // { name: `${t("Numero")}`, field: "numero", sortable: true },
    // { name: `${t("Fecha")}`, field: "fecha", sortable: true },
    { name: `${t('No. Interno')}`, field: 'numeroInterno', sortable: true },
    { name: `${t('Contenedor')}`, field: 'contenedor', sortable: true },
    { name: `${t('ETA')}`, field: 'etaReal', sortable: true },
    { name: `${t('Naviera')}`, field: 'navieras', sortable: true },
    { name: `${t('Cliente')}`, field: 'clientes', sortable: true },
    { name: `${t('Agente Aduanal')}`, field: 'agentes', sortable: true },
    { name: `${t('Forwarder')}`, field: 'forwarders', sortable: true },
    { name: `${t('Patente')}`, field: 'patentes', sortable: true },
    { name: `${t('Puerto')}`, field: 'puerto', sortable: true },
    { name: `${t('Modalidad')}`, field: 'modalidad', sortable: true },
    { name: `${t('Asegurado')}`, field: 'asegurado', sortable: true },
    // { name: `${t("Pendiente")}`, field: "status", sortable: false },
    { name: `${t('Modulacion')}`, field: 'modulacion', sortable: true },
    { name: `${t('Status')}`, field: 'status', sortable: false },
    { name: `${t('Revalidacion')}`, field: 'revalidacion', sortable: true },
    {
      name: `${t('Entrega Cliente')}`,
      field: 'entregaCliente',
      sortable: true,
    },
    { name: `${t('Acciones')}`, field: 'acciones', sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.numeroInterno.toLowerCase().includes(search.toLowerCase()) ||
          comment.contenedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.navieras.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.agentes.toLowerCase().includes(search.toLowerCase()) ||
          comment.status.toLowerCase().includes(search.toLowerCase()) ||
          comment.forwarders.toLowerCase().includes(search.toLowerCase()) ||
          comment.totalCantidad.toString().includes(search) ||
          comment.totalGeneral.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedNaviera) {
      computedComments = computedComments.filter((e) =>
        e.idNavieras.includes(selectedNaviera)
      );
    }

    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }

    if (selectedAgente) {
      computedComments = computedComments.filter((e) =>
        e.idAgentes.includes(selectedAgente)
      );
    }

    if (selectedForwarder) {
      computedComments = computedComments.filter((e) =>
        e.idForwarders.includes(selectedForwarder)
      );
    }

    if (selectedPuerto) {
      computedComments = computedComments.filter((e) =>
        e.puerto.includes(selectedPuerto)
      );
    }

    if (selectedModalidad) {
      computedComments = computedComments.filter((e) =>
        e.modalidad.includes(selectedModalidad)
      );
    }
    if (selectedAsegurado) {
      computedComments = computedComments.filter((e) =>
        e.asegurado.includes(selectedAsegurado)
      );
    }

    if (selectedStatus) {
      computedComments = computedComments.filter((e) =>
        e.status.includes(selectedStatus)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedPendiente) {
      computedComments = computedComments.filter((e) =>
        e.entregaCliente.includes(selectedPendiente)
      );
    }

    if (selectedActivo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(selectedActivo)
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != 'totalCantidad' &&
      sorting.field != 'totalGeneral' &&
      sorting.field != 'numero'
    ) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === 'asc' &&
      (sorting.field == 'totalCantidad' ||
        sorting.field == 'totalGeneral' ||
        sorting.field == 'numero')
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === 'desc' &&
      (sorting.field == 'totalCantidad' ||
        sorting.field == 'totalGeneral' ||
        sorting.field == 'numero')
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedNaviera,
    selectedCliente,
    selectedAgente,
    selectedForwarder,
    selectedPuerto,
    selectedModalidad,
    selectedAsegurado,
    selectedStatus,
    selectedPendiente,
    selectedActivo
  ]);

  async function EditArticulos(idOC, numeroContenedor) {
    setInputFields([
      {
        id: uuidv4(),
        articulos: '',
        descripcion: '',
        cantidad: 0,
        precio: 0,
        total: 0,
      },
    ]);
    setIdEdit(idOC);
    setNumeroContenedor(numeroContenedor);
    await axios
      .get(`${URL_ARTICULOS_CONTENEDOR}/contenedor/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      })
      .then((response) => {
        let allArticulosContenedor = response.data;
        let arrayTabla = allArticulosContenedor
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0]._id,
              cantidad: a.cantidad,
              descripcion: a.descripcion,
              precio: a.precio,
              total: a.total,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setArticulosContenedor(dataFinal);

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TC);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditArticulos();
  }

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosContenedor = articulosContenedor.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosContenedor(newArticulosContenedor);
    ActualizaTotales();
  };

  const handleChangeInputExistentesCantidad = (id, event) => {
    const newArticulosContenedor = articulosContenedor.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosContenedor(newArticulosContenedor);
    ActualizaTotales();
  };

  function BuscaArticuloExistentes(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        handleChangeInputExistentesArticulo(id, idArticulo);
      }
    });
  }

  const handleChangeInputExistentesArticulo = (id, idArticulo) => {
    const newArticulosContenedor = articulosContenedor.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosContenedor(newArticulosContenedor);
    ActualizaTotales();
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: ultimo.articulos,
        cantidad: 0,
        precio: ultimo.precio,
        total: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cantidadExistentes = articulosContenedor.map((c) =>
      parseFloat(c.cantidad)
    );
    let cantidadNuevos = values.map((c) => parseFloat(c.cantidad));
    let cantidad = cantidadExistentes.concat(cantidadNuevos);
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TC);

    let importesExistentes = articulosContenedor.map((c) =>
      parseFloat(c.total)
    );
    let importesNuevos = values.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        handleChangeInputArticulo(id, idArticulo);
      }
    });
  }

  const handleChangeInputArticulo = (id, idArticulo) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cantidadExistentes = articulosContenedor.map((c) =>
      parseFloat(c.cantidad)
    );
    let cantidadNuevos = inputFields.map((c) => parseFloat(c.cantidad));
    let cantidad = cantidadExistentes.concat(cantidadNuevos);
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TC);

    let importesExistentes = articulosContenedor.map((c) =>
      parseFloat(c.total)
    );
    let importesNuevos = inputFields.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  }

  const saveContenedor = async (event) => {
    event.preventDefault();
    toggleProgreso();

    let totalArticulos = inputFields.length + articulosContenedor.length;

    const URL_CONTENEDORES_EDIT = `${process.env.REACT_APP_URL_CONTENEDORES}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_CONTENEDORES_EDIT,
          {
            totalGeneral: totalGeneral_edit,
            totalCantidad: totalCantidad_edit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            if (a.articulos != '' && a.cantidad > 0) {
              axios
                .post(
                  URL_ARTICULOS_CONTENEDOR,
                  {
                    contenedores: idEdit,
                    articulos: a.articulos,
                    descripcion: a.descripcion,
                    cantidad: parseFloat(a.cantidad),
                    precio: parseFloat(a.precio),
                    total: parseFloat(a.total),
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        'app_token'
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: 'Editar Contenedor',
                          detalle: `${numeroContenedor}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              'app_token'
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire('Good job!', 'Creado con exito', 'success');
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: 'Something went wrong!',
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
              // cerrar if
            } else {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: 'Editar Contenedor',
                      detalle: `${numeroContenedor}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          'app_token'
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire('Good job!', 'Creado con exito', 'success');
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Something went wrong!',
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              }
            }
          });

          articulosContenedor.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_CONTENEDOR}/${a.id}`,
                {
                  articulos: a.articulos,
                  cantidad: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  total: parseFloat(a.total),
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      'app_token'
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: 'Editar Contenedor',
                        detalle: `${numeroContenedor}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            'app_token'
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire('Good job!', 'Creado con exito', 'success');
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function EditStatus(
    id,
    bl,
    pl,
    factura,
    numeroInterno,
    documentos,
    telex,
    toquePiso,
    revalidacion,
    fichaTecnica,
    pagoPedimento,
    modulacion,
    retornoVacio,
    salidaPuerto,
    salidaRuta,
    maniobras,
    entregaCliente,
    status,
    fechaEntradaPuerto,
    fechaDocumentos,
    fechaTelex,
    fechaToquePiso,
    fechaRevalidacion,
    fechaFichaTecnica,
    fechaPagoPedimento,
    fechaModulacion,
    fechaRetornoVacio,
    fechaSalidaPuerto,
    fechaSalidaRuta,
    fechaManiobras,
    fechaEntregaCliente,
    fechaFactura,
    fechaBl,
    fechaPl
  ) {
    setid(id);
    setbl(bl);
    setpl(pl);
    setfactura(factura);
    setnumeroInterno(numeroInterno);
    setdocumentos(documentos);
    settelex(telex);
    settoquePiso(toquePiso);
    setrevalidacion(revalidacion);
    setfichaTecnica(fichaTecnica);
    setpagoPedimento(pagoPedimento);
    setmodulacion(modulacion);
    setretornoVacio(retornoVacio);
    setsalidaPuerto(salidaPuerto);
    setsalidaRuta(salidaRuta);
    setmaniobras(maniobras)
    setentregaCliente(entregaCliente);
    setstatus(status);
    setfechaEntradaPuerto(fechaEntradaPuerto);
    setfechaDocumentos(fechaDocumentos);
    setfechaTelex(fechaTelex);
    setfechaToquePiso(fechaToquePiso);
    setfechaRevalidacion(fechaRevalidacion);
    setfechaFichaTecnica(fechaFichaTecnica);
    setfechaPagoPedimento(fechaPagoPedimento);
    setfechaModulacion(fechaModulacion);
    setfechaRetornoVacio(fechaRetornoVacio);
    setfechaSalidaPuerto(fechaSalidaPuerto);
    setfechaSalidaRuta(fechaSalidaRuta);
    setfechaManiobras(fechaManiobras)
    setfechaEntregaCliente(fechaEntregaCliente);
    setfechaFactura(fechaFactura);
    setfechaBl(fechaBl);
    setfechaPl(fechaPl);
    toggleStatus();
  }

  function updateStatus(property, value, nombreFecha, fechaS) {
    if (fechaS == '' || fechaS == undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'La fecha no puede estar vacia',
      });
    } else {
      const data = {
        [property]: value,
        [nombreFecha]: fechaS,
        status,
      };

      axios
        .patch(`${URL_CONTENEDORES}/${id}`, data, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
          },
        })
        .then(() => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Actualizado con Exito',
            showConfirmButton: false,
            timer: 2000,
          });

          let temp = comments.map((a) => {
            if (a._id === id) {
              a[nombreFecha] = fechaS;
              a[property] = value;
              a.status = status;
            }
            return a;
          });
          setComments(temp);

          // setTimeout(() => {
          //   setModalStatus(false);
          // }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    }
  }

  function updateOnlyStatus() {
    axios
      .patch(
        `${URL_CONTENEDORES}/${id}`,
        {
          status,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Actualizado con Exito',
          showConfirmButton: false,
          timer: 2000,
        });

        let temp = comments.map((a) => {
          if (a._id === id) {
            a.status = status;
          }
          return a;
        });
        setComments(temp);

        setTimeout(() => {
          setModalStatus(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const calcula = (e) => {
    setTotalUSD(e);
    setTotal(parseFloat(e) * parseFloat(tipoCambio));
  };

  const calculaTipoCambio = (e) => {
    setTipoCambio(e);
    setTotal(parseFloat(totalUSD) * parseFloat(e));
  };

  function saveGasto() {
    setValidaBoton(false);
    axios
      .post(
        URL_GASTOS_CONTENEDOR,
        {
          fecha: fechaGasto,
          totalUSD,
          tipoCambio,
          total,
          abonos: 0,
          saldo: total,
          descripcion,
          conceptosGastos: selectedConcepto,
          contenedores: selectedContenedor,
          proveedores: selectedProveedor,
          clientes: selectedClienteGasto,
          moneda,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: 'Crear Gasto',
            detalle: `Fecha: ${fecha} / Concepto: ${selectedConcepto} / Total: ${total} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
            },
          }
        );
        Swal.fire('Good job!', 'Creado con exito', 'success');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
        console.log(error);
        setValidaBoton(true);
      });
  }

  function saveCargo() {
    setValidaBoton(false);
    axios
      .post(
        URL_CARGOS_CONTENEDOR,
        {
          fecha: fechaGasto,
          totalUSD,
          tipoCambio,
          total,
          abonos: 0,
          saldo: total,
          descripcion,
          conceptosGastos: selectedConcepto,
          contenedores: selectedContenedor,
          clientes: selectedClienteGasto,
          moneda,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: 'Crear Cargo',
            detalle: `Fecha: ${fecha} / Concepto: ${selectedConcepto} / Total: ${total} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
            },
          }
        );
        Swal.fire('Good job!', 'Creado con exito', 'success');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
        console.log(error);
        setValidaBoton(true);
      });
  }

  function excelOC() {
    var option = {};

    option.fileName = numeroInterno;

    option.datas = [
      {
        sheetData: [
          {
            BL: fechaBl,
            PL: fechaPl,
            Factura: fechaFactura,
            FichaTecnica: fechaFichaTecnica,
            Telex: fechaTelex,
            ToquePiso: fechaToquePiso,
            Revalidacion: fechaRevalidacion,
            PagoPedimento: fechaPagoPedimento,
            Documentos: fechaDocumentos,
            Modulacion: fechaModulacion,
            SalidaPuerto: fechaSalidaPuerto,
            SalidaRuta: fechaSalidaRuta,
            Maniobras: fechaManiobras,
            EntregaCliente: fechaEntregaCliente,
            RetornoVacio: fechaRetornoVacio,
          },
        ],
        sheetName: numeroInterno,
        sheetFilter: [
          'BL',
          'PL',
          'Factura',
          'FichaTecnica',
          'Telex',
          'ToquePiso',
          'Revalidacion',
          'PagoPedimento',
          'Documentos',
          'Modulacion',
          'SalidaPuerto',
          'SalidaRuta',
          'Maniobras',
          'EntregaCliente',
          'RetornoVacio',
        ],
        sheetHeader: [
          'BL',
          'PL',
          'Factura',
          'FichaTecnica',
          'Telex',
          'ToquePiso',
          'Revalidacion',
          'PagoPedimento',
          'Documentos',
          'Modulacion',
          'SalidaPuerto',
          'SalidaRuta',
          'Maniobras',
          'EntregaCliente',
          'RetornoVacio',
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  async function registrarSG(id, naviera, contenedor) {

    axios
    .patch(
      `${URL_CONTENEDORES}AltaRastreo/${id}`,
      {
        navieras: naviera,
        contenedor: contenedor
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            'app_token'
          )}`,
        },
      }
    )
    .then(() => {
      Swal.fire('Good job!', 'Creado con exito', 'success');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
    
  }

  function crearMapa(polLatitude,
    polLongitude,
    podLatitude,
    podLongitude,
    vesselLatitude,
    vesselLongitude,
    etaReal
  ){

const etaRealDate = new Date(etaReal)
etaRealDate.setHours(0, 0, 0, 0);
hoy.setHours(0, 0, 0, 0);
  const diasDiferencia = Math.floor((Date.UTC(etaRealDate.getFullYear(), etaRealDate.getMonth(), etaRealDate.getDate()) - 
                                   Date.UTC(hoy.getFullYear(), hoy.getMonth(), hoy.getDate())) / (1000 * 60 * 60 * 24));

    setDiasLlegada(diasDiferencia)

    setOriginCoords([polLatitude, polLongitude]);  // Ningbo, China (puerto de origen)
    setVesselCoords([vesselLatitude, vesselLongitude]);  // Posición actual del barco
    setDestinationCoords([podLatitude, podLongitude]);  // Lázaro Cárdenas, México (puerto de destino)
  
    // Ruta con puntos intermedios
    setCoordenadasMapa([
      // [polLatitude, polLongitude], 
      [vesselLatitude, vesselLongitude], 
      [podLatitude, podLongitude],
      // [30, 140],  // Punto intermedio 1 en el océano
      // [40, -160],
      // [20, -130],  // Punto intermedio 2 en el océano
    ]);

    toggleMapa()
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menuContenedores ? (
        <div className='card col-12'>
          <Row>
            <Col md={8}>
              {user.contenedoresCreate ? (
                <Button
                  size='sm'
                  type='submit'
                  className='btn btn-success'
                  href='/ContenedoresCreate'
                >
                  {t('Nuevo')}
                </Button>
              ) : (
                <Button
                  size='sm'
                  type='submit'
                  className='btn btn-success'
                  disabled
                >
                  {t('Nuevo')}
                </Button>
              )}
              <Button
                size='sm'
                href='/MenuContenedores'
                className='btn btn-danger'
                id='botonListado'
              >
                {t('Regresar')}
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id='logoutBoton'>
                {/* <Button
                  size='sm'
                  className='btn'
                  color='success'
                  onClick={toggleETA}
                >
                  ETAs
                </Button> */}
                <Button
                  size='sm'
                  className='btn'
                  color='danger'
                  onClick={PDFTabla}
                >
                  PDF <i class='far fa-file-pdf'></i>
                </Button>
                <Button
                  size='sm'
                  className='btn'
                  onClick={toggleMail}
                  color='info'
                >
                  eMail <i class='fas fa-at'></i>
                </Button>
                <Button
                  size='sm'
                  className='btn'
                  color='primary'
                  onClick={excel}
                >
                  Excel <i class='far fa-file-excel'></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size='sm' isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>{t('Enviar Listado Contenedores')}</h4>
            </ModalHeader>
            <ModalBody>
              <Label className='mr-sm-2'>Email</Label>
              <Input
                className='col-sm-12'
                type='text'
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size='sm' color='success' onClick={enviaMail}>
                {t('Enviar')}
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align='center'>{t('Contenedores')}</h3>
          <div className='row'>
            <div className='col-md-8'>
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className='col-md-4 d-flex flex-row-reverse'>
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className='mr-sm-2'>{t('Fecha Inicio')}</Label>
              <Input
                bsSize='sm'
                type='date'
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className='mr-sm-2'>{t('Fecha Fin')}</Label>
              <Input
                bsSize='sm'
                type='date'
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>

            <Col md={2}>
              <Label className='mr-sm-2'>{t('Pendientes')}</Label>
              <Input
                bsSize='sm'
                type='select'
                value={selectedPendiente}
                required
                onChange={(e) => {
                  setSelectedPendiente(e.target.value);
                }}
              >
                <option value=''>{t('Selecciona')}</option>
                <option value='No'>{t('Pendiente')}</option>
                <option value='Si'>{t('Entregado')}</option>
              </Input>
            </Col>

            <Col md={2}>
              <Label className='mr-sm-2'>{t('Activo')}</Label>
              <Input
                bsSize='sm'
                type='select'
                value={selectedActivo}
                required
                onChange={(e) => {
                  setSelectedActivo(e.target.value);
                }}
              >
                <option value=''>{t('Selecciona')}</option>
                <option value='Si'>{t('Activo')}</option>
                <option value='No'>{t('Cancelado')}</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size='sm' striped borderless className='table-responsive-xl'>
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td></td>
              {/* <td></td>
              <td></td> */}
              <td style={{ paddingTop: '0px' }}>
                <Input
                  bsSize='sm'
                  type='select'
                  value={selectedNaviera}
                  onChange={(e) => {
                    setSelectedNaviera(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value=''>{t('Selecciona')}</option>
                  {proveedores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (a.tipo === 'Naviera') {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: '0px' }}>
                <Input
                  bsSize='sm'
                  type='select'
                  value={selectedCliente}
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value=''>{t('Selecciona')}</option>
                  {clientes
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: '0px' }}>
                <Input
                  bsSize='sm'
                  type='select'
                  value={selectedAgente}
                  onChange={(e) => {
                    setSelectedAgente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value=''>{t('Selecciona')}</option>
                  {proveedores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (a.tipo === 'Agente') {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: '0px' }}>
                <Input
                  bsSize='sm'
                  type='select'
                  value={selectedForwarder}
                  onChange={(e) => {
                    setSelectedForwarder(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value=''>{t('Selecciona')}</option>
                  {proveedores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (a.tipo === 'Forwarder') {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </td>
              <td></td>
              <td style={{ paddingTop: '0px' }}>
                <Input
                  bsSize='sm'
                  type='select'
                  placeholder='Puerto'
                  value={selectedPuerto}
                  required
                  onChange={(e) => {
                    setSelectedPuerto(e.target.value);
                  }}
                >
                  <option value=''>Selecciona</option>
                  <option value='Aeropuero CDMX'>Aeropuero CDMX</option>
                  <option value='Laredo'>Laredo</option>
                  <option value='Lazaro Cardenas'>Lazaro Cardenas</option>
                  <option value='Manzanillo'>Manzanillo</option>
                  <option value='Progreso'>Progreso</option>
                  <option value='Tijuana'>Tijuana</option>
                  <option value='Veracruz'>Veracruz</option>
                </Input>
              </td>
              <td style={{ paddingTop: '0px' }}>
                <Input
                  bsSize='sm'
                  type='select'
                  placeholder='Modalidad'
                  value={selectedModalidad}
                  required
                  onChange={(e) => {
                    setSelectedModalidad(e.target.value);
                  }}
                >
                  <option value=''>Selecciona</option>
                  <option value='Tren'>Tren</option>
                  <option value='Camion'>Camion</option>
                </Input>
              </td>
              <td style={{ paddingTop: '0px' }}>
                <Input
                  bsSize='sm'
                  type='select'
                  placeholder='Asegurado'
                  value={selectedAsegurado}
                  required
                  onChange={(e) => {
                    setSelectedAsegurado(e.target.value);
                  }}
                >
                  <option value=''>Selecciona</option>
                  <option value='No'>No</option>
                  <option value='Si'>Si</option>
                  <option value='No Aplica'>No Aplica</option>
                </Input>
              </td>
              <td style={{ paddingTop: '0px' }}></td>
              <td style={{ paddingTop: '0px' }}>
                <Input
                  type='select'
                  bsSize='sm'
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value=''>Selecciona</option>
                  <option value='Pendiente'>Pendiente</option>
                  {/* <option value='BL'>BL</option>
                  <option value='PL'>PL</option>
                  <option value='Factura'>Factura</option> */}
                  <option value='Ficha Tecnica'>Ficha Tecnica</option>
                  <option value='Toque Piso'>Toque Piso</option>
                  <option value='Telex'> Telex</option>
                  <option value='Naviera Freno Demoras'>
                    Naviera Freno Demoras
                  </option>
                  <option value='Revalidacion'>Revalidacion</option>
                  <option value='Pago Pedimento'>Pago Pedimento</option>
                  <option value='Por Modular'>Por Modular</option>
                  {/* <option value='Modulado'>Modulado</option> */}
                  <option value='Por Documentar Tren'>
                    Por Documentar Tren
                  </option>
                  <option value='Por subir Tren'>Por subir Tren</option>
                  <option value='Maniobra Transportista'>
                    Maniobra Transportista
                  </option>
                  {/* <option value='Maniobra Racs'>Maniobra Racs</option>
                  <option value='Maniobra Conia'>Maniobra Conia</option> 
                  <option value='Patio Racs'>Patio Racs</option>
                  <option value='Patio Conia'>Patio Conia</option>*/}
                  <option value='Patio Transportista'>
                    Patio Transportista
                  </option>
                  <option value='A1'>A1</option>
                  <option value='PAMA'>PAMA</option>
                  <option value='ROJO'>ROJO</option>
                  <option value='OVT'>OVT</option>
                  <option value='RETENIDO DESDE LLEGADA'>RETENIDO DESDE LLEGADA</option>
                  <option value='Bodega Tortons'>Bodega Tortons</option>
                  <option value='En Tren'>En Tren</option>
                  <option value='Pendiente Maniobras'>Pendiente Maniobras</option>
                  <option value='Entregado'>Entregado</option>
                  <option value='Cancelado'>Cancelado</option>
                </Input>
              </td>
              <td style={{ paddingTop: '0px' }}></td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                return (
                  <tr>
                    {/* <td>{a.numero}</td>
                    <td>{a.fecha}</td> */}
                    <td style={{ paddingTop: '0px' }}>{a.numeroInterno}</td>
                    <td style={{ paddingTop: '0px' }}>{a.contenedor}</td>
                    <td style={{ paddingTop: '0px' }}>{a.etaReal}</td>
                    <td style={{ paddingTop: '0px' }}>{a.navieras}</td>
                    <td style={{ paddingTop: '0px' }}>{a.clientes}</td>
                    <td style={{ paddingTop: '0px' }}>{a.agentes}</td>
                    <td style={{ paddingTop: '0px' }}>{a.forwarders}</td>
                    <td style={{ paddingTop: '0px' }}>{a.patentes}</td>
                    <td style={{ paddingTop: '0px' }}>{a.puerto}</td>
                    <td style={{ paddingTop: '0px' }}>{a.modalidad}</td>
                    <td style={{ paddingTop: '0px' }}>{a.asegurado}</td>
                    {/* <td style={{ fontSize: '.7rem' }}>
                    {a.bl == "No" ? "BL, " : undefined}
                    {a.pl == "No" ? "Packing List, " : undefined}
                    {a.factura == "No" ? "Factura, " : undefined}
                    {a.fichaTecnica == "No" ? "Ficha Tecnica, " : undefined}
                    {a.telex == "No" ? "Telex, " : undefined}
                    {a.toquePiso == "No" ? "Toque Piso, " : undefined}
                    {a.revalidacion == "No" ? "Revalidacion, " : undefined}
                    {a.pagoPedimento == "No" ? "Pago Pedimento, " : undefined}
                    {a.documentos == "No" ? "Documentos, " : undefined}
                    {a.modulacion == "No" ? "Modulacion, " : undefined}
                    </td> */}
                    <td style={{ paddingTop: '0px' }}>{a.fechaModulacion}</td>
                    <td style={{ paddingTop: '0px' }}>{a.status}</td>
                    <td style={{ paddingTop: '0px' }}>{a.fechaRevalidacion}</td>
                    <td style={{ paddingTop: '0px' }}>
                      {a.fechaEntregaCliente}
                    </td>

                    <td style={{ paddingTop: '0px' }}>
                      <Button
                        size='sm'
                        className='btn'
                        color='danger'
                        onClick={(e) =>
                          PDFOC(
                            a.fecha,
                            a.idNavieras,
                            a.navieras,
                            a.clientes,
                            a.agentes,
                            a.totalCantidad,
                            a.totalGeneral,
                            a.observaciones,
                            a.numero,
                            a._id
                          )
                        }
                      >
                        <i class='far fa-file-pdf'></i>
                        </Button>
                      {a.rastreo == 'No' && a.idNavieras != "65a093c4b196000019bbeca4" ? (
                      <Button
                      size='sm'
                        color='success'
                        onClick={(e) =>{
                          registrarSG(a._id, a.navieras, a.contenedor)
                        }
                        }
                      >
                        SG
                      </Button>
                      ):(
                        <Button
                          size='sm'
                        color='success'
                          disabled
                          >
                            SG
                          </Button>)}
                      {a.mapa !== 'No' ? (
                      <Button
                      size='sm'
                        onClick={(e) =>{
                          crearMapa(a.polLatitude,
                            a.polLongitude,
                            a.podLatitude,
                            a.podLongitude,
                            a.vesselLatitude,
                            a.vesselLongitude,
                            a.etaReal
                          )
                        }
                        }
                      >
                        <i class='fas fa-map'></i>
                      </Button>
                      ):(
                        <Button
                          size='sm'
                          disabled
                          >
                            <i class='fas fa-map'></i>
                          </Button>)}
                      <Button
                        color='info'
                        id='Editar'
                        size='sm'
                        onClick={(e) =>
                          jalaInfo(
                            a.fecha,
                            a.idNavieras,
                            a.idClientes,
                            a.idAgentes,
                            a.idForwarders,
                            a.totalCantidad,
                            a.totalGeneral,
                            a.observaciones,
                            a.observacionesInternas,
                            a.numero,
                            a.numeroInterno,
                            a.contenedor,
                            // a.etaEstimada,
                            a.etaReal,
                            a.puerto,
                            a.idTerminales,
                            a.pedimento,
                            a.modalidad,
                            a.asegurado,
                            a.direccionEntrega,
                            a.idProgramas,
                            a.idPatentes,
                            a.rastreo,
                            a._id
                          )
                        }
                      >
                        <i class='far fa-edit'></i>
                      </Button>
                      <Button
                        color='info'
                        id='Editar'
                        size='sm'
                        onClick={(e) => EditArticulos(a._id, a.numero)}
                      >
                        <i class='fas fa-cart-plus'></i>
                      </Button>

                      <Button
                        color='success'
                        id='Editar'
                        size='sm'
                        onClick={(e) =>
                          EditStatus(
                            a._id,
                            a.bl,
                            a.pl,
                            a.factura,
                            a.numeroInterno,
                            a.documentos,
                            a.telex,
                            a.toquePiso,
                            a.revalidacion,
                            a.fichaTecnica,
                            a.pagoPedimento,
                            a.modulacion,
                            a.retornoVacio,
                            a.salidaPuerto,
                            a.salidaRuta,
                            a.maniobras,
                            a.entregaCliente,
                            a.status,
                            a.fechaEntradaPuerto,
                            a.fechaDocumentos,
                            a.fechaTelex,
                            a.fechaToquePiso,
                            a.fechaRevalidacion,
                            a.fechaFichaTecnica,
                            a.fechaPagoPedimento,
                            a.fechaModulacion,
                            a.fechaRetornoVacio,
                            a.fechaSalidaPuerto,
                            a.fechaSalidaRuta,
                            a.fechaManiobras,
                            a.fechaEntregaCliente,
                            a.fechaFactura,
                            a.fechaBl,
                            a.fechaPl
                          )
                        }
                      >
                        <i class='fas fa-calendar-check'></i>
                      </Button>
                      {user.contenedoresEntrega?(
                      <Baja
                        idStatus={a._id}
                        is_active={a.activo}
                        URL_BAJA={process.env.REACT_APP_URL_CONTENEDORES}
                      />
                    ):undefined}
                      {/* <Button
                        color="primary"
                        id="Editar"
                        size="sm"
                        onClick={(e) => {
                          setSelectedContenedor(a._id);
                          setSelectedClienteGasto(a.idClientes);
                          setnumeroInterno(a.numeroInterno);
                          toggleGastos();
                        }}
                      >
                        <i class="fas fa-dollar-sign"></i>
                      </Button>
                      <Button
                        color="info"
                        id="Editar"
                        size="sm"
                        onClick={(e) => {
                          setSelectedContenedor(a._id);
                          setSelectedClienteGasto(a.idClientes);
                          setnumeroInterno(a.numeroInterno);
                          toggleCargos();
                        }}
                      >
                        <i class="fas fa-plus"></i>
                      </Button> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className='col-md-6'>
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size='xxl' isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            {t('Editar')} {t('Contenedor')} {numeroContenedor}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>{t('Contenedor')}</Label>
              <Input
                type='text'
                placeholder='Contenedor'
                value={contenedor}
                required
                onChange={(e) => {
                  setContenedor(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>{t('Fecha')}</Label>
              <Input
                type='date'
                placeholder='Fecha'
                value={fecha}
                required
                disabled
              />
            </Col>

            <Col md={3}>
              <Label>{t('Numero Interno')}</Label>
              <Input
                type='text'
                placeholder='numeroInterno'
                value={numeroInterno}
                required
                disabled
              />
            </Col>

            <Col md={3}>
              <Label className='mr-sm-2'>{t('Cliente')}</Label>
              <Input
                className='col-sm-12'
                type='select'
                value={selectedClienteEdit}
                required
                disabled
              >
                <option value='0'>{t('Selecciona')}</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label className='mr-sm-2'>{t('Naviera')}</Label>
             {rastreo == "No" ?(
               <Input
               className='col-sm-12'
               type='select'
               value={selectedNavieraEdit}
               required
               onChange={(e) => {
                 setSelectedNavieraEdit(e.target.value);
               }}
             >
               <option value='0'>{t('Selecciona')}</option>
               {proveedores
                 .sort((a, b) => (a.name > b.name ? 1 : -1))
                 .map((a) => {
                   if (a.tipo === 'Naviera') {
                     return <option value={a._id}>{a.name}</option>;
                   }
                 })}
             </Input>
             ):( 
             <Input
              className='col-sm-12'
              type='select'
              value={selectedNavieraEdit}
              required
              disabled
            >
              <option value='0'>{t('Selecciona')}</option>
              {proveedores
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((a) => {
                  if (a.tipo === 'Naviera') {
                    return <option value={a._id}>{a.name}</option>;
                  }
                })}
            </Input>)}
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>{t('Agente Aduanal')}</Label>
              <Input
                className='col-sm-12'
                type='select'
                value={selectedAgenteEdit}
                required
                onChange={(e) => {
                  setSelectedAgenteEdit(e.target.value);
                }}
              >
                <option value='0'>{t('Selecciona')}</option>
                {proveedores
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if (a.tipo === 'Agente') {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
              </Input>
            </Col>

            <Col md={3}>
              <Label className='mr-sm-2'>{t('Forwarder')}</Label>
              <Input
                className='col-sm-12'
                type='select'
                value={selectedForwarderEdit}
                required
                onChange={(e) => {
                  setSelectedForwarderEdit(e.target.value);
                }}
              >
                <option value='0'>{t('Selecciona')}</option>
                {proveedores
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if (a.tipo === 'Forwarder') {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
              </Input>
            </Col>

            <Col md={3}>
              <Label>{t('Puerto')}</Label>
              <Input
                type='select'
                placeholder='Puerto'
                value={puerto}
                required
                onChange={(e) => {
                  setPuerto(e.target.value);
                }}
              >
                <option value=''>Selecciona</option>
                <option value='Aeropuero CDMX'>Aeropuero CDMX</option>
                <option value='Laredo'>Laredo</option>
                <option value='Lazaro Cardenas'>Lazaro Cardenas</option>
                <option value='Manzanillo'>Manzanillo</option>
                <option value='Progreso'>Progreso</option>
                <option value='Tijuana'>Tijuana</option>
                <option value='Veracruz'>Veracruz</option>
              </Input>
            </Col>

            <Col md={3}>
              <Label>{t('Terminal')}</Label>
              <Input
                type='select'
                placeholder='Terminal'
                value={selectedTerminal}
                required
                onChange={(e) => {
                  setSelectedTerminal(e.target.value);
                }}
              >
                {terminales
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <Label>{t('Pedimento')}</Label>
              <Input
                type='text'
                placeholder='Pedimento'
                value={pedimento}
                required
                onChange={(e) => {
                  setPedimento(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{t('Programa')}</Label>
              <Input
                type='select'
                placeholder='Programa'
                value={selectedPrograma}
                required
                onChange={(e) => {
                  setSelectedPrograma(e.target.value);
                }}
              >
                {programas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label>{t('Patente')}</Label>
              <Input
                type='select'
                placeholder='Patente'
                value={selectedPatente}
                required
                onChange={(e) => {
                  setSelectedPatente(e.target.value);
                }}
              >
                {patentes
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label>{t('Modalidad')}</Label>
              <Input
                type='select'
                placeholder='Modalidad'
                value={modalidad}
                required
                onChange={(e) => {
                  setModalidad(e.target.value);
                }}
              >
                <option value=''>Selecciona</option>
                <option value='Tren'>Tren</option>
                <option value='Camion'>Camion</option>
              </Input>
            </Col>
            {/* <Col md={3}>
              <Label>
                {t("Fecha")} ETA {t("Estimada")}
              </Label>
              <Input
                type="date"
                value={fechaETAEstimada}
                required
                onChange={(e) => {
                  setFechaETAEstimada(e.target.value);
                }}
              />
            </Col> */}
            <Col md={3}>
              <Label>{t('Asegurado')}</Label>
              <Input
                type='select'
                placeholder='Asegurado'
                value={asegurado}
                required
                onChange={(e) => {
                  setAsegurado(e.target.value);
                }}
              >
                <option value=''>Selecciona</option>
                <option value='No'>No</option>
                <option value='Si'>Si</option>
                <option value='No Aplica'>No Aplica</option>
              </Input>
            </Col>
            <Col md={3}>
              <Label>{t('Fecha')} ETA</Label>
              <Input
                type='date'
                value={fechaETAReal}
                required
                onChange={(e) => {
                  setFechaETAReal(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <Label>{t('Direccion de Entrega')}</Label>
              <Input
                type='text'
                placeholder='Direccion Entrega'
                value={direccionEntrega}
                required
                onChange={(e) => {
                  setDireccionEntrega(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <Label for='exampleEmail' className='mr-sm-2'>
                {t('Observaciones')}
              </Label>
              <Input
                type='textarea'
                placeholder='Observaciones'
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for='exampleEmail' className='mr-sm-2'>
                {t('Observaciones Internas')}
              </Label>
              <Input
                type='textarea'
                placeholder='ObservacionesInternas'
                value={observacionesInternas}
                required
                onChange={(e) => {
                  setObservacionesInternas(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='success' onClick={editContenedores}>
            {t('Guardar')}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size='xxl'
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>
            {'Editar'} {t('Articulos')} {t('Contenedor')} {numeroContenedor}
          </h4>
          <h4>
            TOTAL {totalCantidad_edit} pzas. /{' '}
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
            }).format(totalGeneral_edit)}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={2}>
              <Label className='mr-sm-2'>{t('Articulos')}</Label>
            </Col>
            <Col md={2}>
              <Label className='mr-sm-2'>{t('Descripcion')}</Label>
            </Col>
            <Col md={1}>
              <Label className='mr-sm-2'>{t('Cantidad')}</Label>
            </Col>
            <Col md={1}>
              <Label className='mr-sm-2'>{t('Precio')}</Label>
            </Col>
            <Col md={2}>
              <Label className='mr-sm-2'>Total</Label>
            </Col>
          </Row>

          {articulosContenedor.map((ao) => (
            <div key={ao.id}>
              <Row>
                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name='articulos'
                    type='select'
                    value={ao.articulos}
                    required
                    onChange={(event) => {
                      BuscaArticuloExistentes(ao.id, event);
                    }}
                  >
                    <option value='0'>{t('Selecciona')}</option>
                    {articulos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </Col>

                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name='descripcion'
                    type='text'
                    placeholder='descripcion'
                    value={ao.descripcion}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentes(ao.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name='cantidad'
                    type='number'
                    min='0'
                    step='any'
                    placeholder='Cantidad'
                    value={ao.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentesCantidad(ao.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name='precio'
                    type='number'
                    min='0'
                    step='any'
                    placeholder='Precio'
                    value={ao.precio}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentes(ao.id, event)
                    }
                  />
                </Col>
                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name='total'
                    type='number'
                    min='0'
                    step='any'
                    placeholder='Total'
                    value={ao.total}
                    disabled
                    onChange={(event) =>
                      handleChangeInputExistentes(ao.id, event)
                    }
                  />
                </Col>
              </Row>
            </div>
          ))}

          {/* Agregar mas articulos */}

          {inputFields.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name='articulos'
                    type='select'
                    value={inputField.articulos}
                    required
                    onChange={(event) => {
                      BuscaArticulo(inputField.id, event);
                    }}
                  >
                    <option value='0'>{t('Selecciona')}</option>
                    {articulos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </Col>

                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name='descripcion'
                    type='text'
                    placeholder='descripcion'
                    value={inputField.descripcion}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name='cantidad'
                    type='number'
                    min='0'
                    step='any'
                    placeholder='Cantidad'
                    value={inputField.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name='precio'
                    type='number'
                    min='0'
                    step='any'
                    placeholder='Precio'
                    value={inputField.precio}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>

                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name='total'
                    type='number'
                    min='0'
                    step='any'
                    placeholder='Total'
                    value={inputField.total}
                    disabled
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>
                <Col>
                  <Button
                    size='sm'
                    className='btn'
                    color='danger'
                    disabled={inputFields.length === 1}
                    onClick={() => handleRemoveFields(inputField.id)}
                    tabindex='-1'
                  >
                    <i class='fas fa-minus'></i>
                  </Button>
                  <Button
                    size='sm'
                    className='btn'
                    color='info'
                    onClick={handleAddFields}
                    tabindex='-1'
                  >
                    <i class='fas fa-plus'></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Row>
            <Col md={6}>
              <h4 id='logoutBoton'>
                TOTAL {totalCantidad_edit} pzas. /{' '}
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 2,
                }).format(totalGeneral_edit)}
              </h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button size='sm' color='success' onClick={saveContenedor}>
            {t('Guardar')}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size='sm' isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>{t('Progreso')}</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {' '}
            {t('Este proceso puede tardar varios segundos.')}
            <br />
            {t('Por favor no cierre ni refresque su navegador.')}
          </h4>
          <br />
          <div className='progreso'>
            <Spinner
              style={{
                width: '50px',
                height: '50px',
                color: '#232B41',
              }}
            />
            <Spinner
              style={{
                width: '50px',
                height: '50px',
                color: '#232B41',
              }}
            />
            <Spinner
              style={{
                width: '50px',
                height: '50px',
                color: '#232B41',
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size='lg' isOpen={modalStatus} toggle={toggleStatus}>
        <ModalHeader toggle={toggleStatus}>
          <h4>
            {t('Status')} Contenedor {numeroInterno}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Button size='sm' className='btn' color='primary' onClick={excelOC}>
              Excel <i class='far fa-file-excel'></i>
            </Button>
          </Row>
          <Row>
            <Col md={4}>
              <Label>Status</Label>
              <Input
                type='select'
                value={status}
                onChange={(e) => setstatus(e.target.value)}
              >
                <option value='Pendiente'>Pendiente</option>
                {/* <option value='BL'>BL</option>
                <option value='PL'>PL</option>
                <option value='Factura'>Factura</option> */}
                <option value='Ficha Tecnica'>Ficha Tecnica</option>
                <option value='Toque Piso'>Toque Piso</option>
                <option value='Telex'> Telex</option>
                <option value='Naviera Freno Demoras'>
                  Naviera Freno Demoras
                </option>
                <option value='Revalidacion'>Revalidacion</option>
                <option value='Pago Pedimento'>Pago Pedimento</option>
                <option value='Por Modular'>Por Modular</option>
                {/* <option value='Modulado'>Modulado</option> */}
                <option value='Por Documentar Tren'>Por Documentar Tren</option>
                <option value='Por subir Tren'>Por subir Tren</option>
                <option value='Maniobra Transportista'>
                  Maniobra Transportista
                </option>
                {/* <option value='Maniobra Racs'>Maniobra Racs</option>
                <option value='Maniobra Conia'>Maniobra Conia</option>
                <option value='Patio Racs'>Patio Racs</option>
                <option value='Patio Conia'>Patio Conia</option> */}
                <option value='Patio Transportista'>Patio Transportista</option>
                <option value='A1'>A1</option>
                  <option value='PAMA'>PAMA</option>
                  <option value='ROJO'>ROJO</option>
                  <option value='OVT'>OVT</option>
                  <option value='RETENIDO DESDE LLEGADA'>RETENIDO DESDE LLEGADA</option>
                  <option value='Bodega Tortons'>Bodega Tortons</option>
                  <option value='En Tren'>En Tren</option>
                  <option value='Pendiente Maniobras'>Pendiente Maniobras</option>
                { user.contenedoresEntrega?(
                  <>
                <option value='Entregado'>Entregado</option>
                <option value='Cancelado'>Cancelado</option>
                </>
              ): undefined}
              </Input>
              {/* </Col>
                <Col md={3}>
                  <Label>.</Label> */}
              <Button onClick={() => updateOnlyStatus()} size='sm'>
                Actualizar
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3} className='negrita'>
              <Label className='mr-sm-2'>Concepto</Label>
            </Col>
            <Col md={3} className='negrita'>
              <Label className='mr-sm-2'>Fecha</Label>
            </Col>
            <Col md={3} className='negrita'>
              <Label className='mr-sm-2'>Realizado</Label>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>BL</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaBl}
                onChange={(e) => setfechaBl(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{bl === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={bl === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'bl',
                    bl === 'Si' ? 'No' : 'Si',
                    'fechaBl',
                    fechaBl
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>Packing List</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaPl}
                onChange={(e) => setfechaPl(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{pl === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={pl === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'pl',
                    pl === 'Si' ? 'No' : 'Si',
                    'fechaPl',
                    fechaPl
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>Factura</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaFactura}
                onChange={(e) => setfechaFactura(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{factura === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={factura === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'factura',
                    factura === 'Si' ? 'No' : 'Si',
                    'fechaFactura',
                    fechaFactura
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>FichaTecnica</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaFichaTecnica}
                onChange={(e) => setfechaFichaTecnica(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{fichaTecnica === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={fichaTecnica === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'fichaTecnica',
                    fichaTecnica === 'Si' ? 'No' : 'Si',
                    'fechaFichaTecnica',
                    fechaFichaTecnica
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>Telex</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaTelex}
                onChange={(e) => setfechaTelex(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{telex === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={telex === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'telex',
                    telex === 'Si' ? 'No' : 'Si',
                    'fechaTelex',
                    fechaTelex
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>Toque de Piso</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaToquePiso}
                onChange={(e) => setfechaToquePiso(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{toquePiso === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={toquePiso === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'toquePiso',
                    toquePiso === 'Si' ? 'No' : 'Si',
                    'fechaToquePiso',
                    fechaToquePiso
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>Revalidacion</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaRevalidacion}
                onChange={(e) => setfechaRevalidacion(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{revalidacion === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={revalidacion === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'revalidacion',
                    revalidacion === 'Si' ? 'No' : 'Si',
                    'fechaRevalidacion',
                    fechaRevalidacion
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>Pago Pedimento</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaPagoPedimento}
                onChange={(e) => setfechaPagoPedimento(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{pagoPedimento === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={pagoPedimento === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'pagoPedimento',
                    pagoPedimento === 'Si' ? 'No' : 'Si',
                    'fechaPagoPedimento',
                    fechaPagoPedimento
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />
          {/* <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Documentos</Label>
            </Col>
            <Col md={3} className="negro">
              <Input
                type="date"
                value={fechaDocumentos}
                onChange={(e) => setfechaDocumentos(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{documentos === "Si" ? "Listo" : "Pendiente"}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={documentos === "Si" ? "primary" : "danger"}
                onClick={() =>
                  updateStatus(
                    "documentos",
                    documentos === "Si" ? "No" : "Si",
                    "fechaDocumentos",
                    fechaDocumentos
                  )
                }
                size="sm"
              >
                <i className="fas fa-check-circle"></i>
              </Button>
            </Col>
          </Row>
          <br /> */}
          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>Modulacion</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaModulacion}
                onChange={(e) => setfechaModulacion(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{modulacion === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={modulacion === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'modulacion',
                    modulacion === 'Si' ? 'No' : 'Si',
                    'fechaModulacion',
                    fechaModulacion
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />

          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>Salida de Puerto</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaSalidaPuerto}
                onChange={(e) => setfechaSalidaPuerto(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{salidaPuerto === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={salidaPuerto === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'salidaPuerto',
                    salidaPuerto === 'Si' ? 'No' : 'Si',
                    'fechaSalidaPuerto',
                    fechaSalidaPuerto
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>Salida Ruta</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaSalidaRuta}
                onChange={(e) => setfechaSalidaRuta(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{salidaRuta === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={salidaRuta === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'salidaRuta',
                    salidaRuta === 'Si' ? 'No' : 'Si',
                    'fechaSalidaRuta',
                    fechaSalidaRuta
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />

          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>Maniobras</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaManiobras}
                onChange={(e) => setfechaManiobras(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{maniobras === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={maniobras === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'maniobras',
                    maniobras === 'Si' ? 'No' : 'Si',
                    'fechaManiobras',
                    fechaManiobras
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />

          {user.contenedoresEntrega?(
            <>
          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>Entrega Cliente</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaEntregaCliente}
                onChange={(e) => setfechaEntregaCliente(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{entregaCliente === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={entregaCliente === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'entregaCliente',
                    entregaCliente === 'Si' ? 'No' : 'Si',
                    'fechaEntregaCliente',
                    fechaEntregaCliente
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />
          </>
        ):undefined}
          <Row>
            <Col md={3}>
              <Label className='mr-sm-2'>Retorno Vacio</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaRetornoVacio}
                onChange={(e) => setfechaRetornoVacio(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{retornoVacio === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={3}>
              <Button
                color={retornoVacio === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'retornoVacio',
                    retornoVacio === 'Si' ? 'No' : 'Si',
                    'fechaRetornoVacio',
                    fechaRetornoVacio
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>
          </Row>
          <br />
        </ModalBody>
      </Modal>

      <Modal size='lg' isOpen={modalGastos} toggle={toggleGastos}>
        <ModalHeader toggle={toggleGastos}>
          <h4>
            {t('Gastos')} Contenedor {numeroInterno}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <Label className='mr-sm-2'>{t('Fecha')}</Label>
              <Input
                type='date'
                placeholder='Fecha'
                value={fechaGasto}
                required
                onChange={(e) => {
                  setFechaGasto(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className='mr-sm-2'>Proveedor</Label>
              <Input
                // bsSize="sm"
                type='select'
                required
                value={selectedProveedor}
                onChange={(e) => setSelectedProveedor(e.target.value)}
              >
                <option value='0'>{t('Selecciona')}</option>
                {proveedores
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={6}>
              <Label className='mr-sm-2'>{t('Cliente')}</Label>
              <Input
                // bsSize="sm"
                type='select'
                required
                value={selectedClienteGasto}
                disabled
              >
                <option value='0'>{t('Selecciona')}</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Label className='mr-sm-2'>{t('Concepto')}</Label>
              <Input
                // bsSize="sm"
                type='select'
                required
                value={selectedConcepto}
                onChange={(e) => setSelectedConcepto(e.target.value)}
              >
                <option value='0'>{t('Selecciona')}</option>
                {conceptosGastos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={6}>
              <Label for='exampleEmail' className='mr-sm-2'>
                {t('Descripcion')}
              </Label>
              <Input
                type='text'
                placeholder='Descripcion'
                value={descripcion}
                required
                onChange={(e) => {
                  setDescripcion(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className='mr-sm-2'>{t('Moneda')}</Label>
              <Input
                // bsSize="sm"
                type='select'
                required
                value={moneda}
                onChange={(e) => {
                  setMoneda(e.target.value);
                  setTotalUSD(0);
                  setTotal(0);
                  setTipoCambio(1);
                }}
              >
                <option value='MXN'>{t('MXN')}</option>
                <option value='USD'>{t('USD')}</option>
              </Input>
            </Col>
          </Row>
          {moneda === 'USD' ? (
            <Row>
              <Col sm={4}>
                <Label for='exampleEmail' className='mr-sm-2'>
                  {t('Total USD')}
                </Label>
                <Input
                  type='number'
                  min='0'
                  step='any'
                  placeholder='TotalUSD'
                  value={totalUSD}
                  required
                  onChange={(e) => calcula(e.target.value)}
                />
              </Col>
              <Col sm={4}>
                <Label for='exampleEmail' className='mr-sm-2'>
                  Tipo de Cambio
                </Label>
                <Input
                  type='number'
                  min='0'
                  step='any'
                  placeholder='TipoCambio'
                  value={tipoCambio}
                  required
                  onChange={(e) => calculaTipoCambio(e.target.value)}
                />
              </Col>
              <Col sm={4}>
                <Label for='exampleEmail' className='mr-sm-2'>
                  {t('Total MXN')}
                </Label>
                <Input
                  type='number'
                  min='0'
                  step='any'
                  value={total}
                  disabled
                  // onChange={(e) => calculaMXN(e.target.value)}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={4}>
                <Label for='exampleEmail' className='mr-sm-2'>
                  {t('Total MXN')}
                </Label>
                <Input
                  type='number'
                  min='0'
                  step='any'
                  value={total}
                  onChange={(e) => {
                    setTotal(e.target.value);
                    setTotalUSD(e.target.value / tipoCambio);
                  }}
                />
              </Col>
            </Row>
          )}
          <br />
          {validaBoton ? (
            <Button color='success' onClick={() => saveGasto()}>
              Guardar
            </Button>
          ) : (
            <Button color='success' disabled>
              Guardar
            </Button>
          )}
        </ModalBody>
      </Modal>

      <Modal size='lg' isOpen={modalCargos} toggle={toggleCargos}>
        <ModalHeader toggle={toggleCargos}>
          <h4>
            {t('Cargos')} Contenedor {numeroInterno}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <Label className='mr-sm-2'>{t('Fecha')}</Label>
              <Input
                type='date'
                placeholder='Fecha'
                value={fechaGasto}
                required
                onChange={(e) => {
                  setFechaGasto(e.target.value);
                }}
              />
            </Col>

            <Col md={6}>
              <Label className='mr-sm-2'>{t('Cliente')}</Label>
              <Input
                // bsSize="sm"
                type='select'
                required
                value={selectedClienteGasto}
                disabled
              >
                <option value='0'>{t('Selecciona')}</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Label className='mr-sm-2'>{t('Concepto')}</Label>
              <Input
                // bsSize="sm"
                type='select'
                required
                value={selectedConcepto}
                onChange={(e) => setSelectedConcepto(e.target.value)}
              >
                <option value='0'>{t('Selecciona')}</option>
                {conceptosGastos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={6}>
              <Label for='exampleEmail' className='mr-sm-2'>
                {t('Descripcion')}
              </Label>
              <Input
                type='text'
                placeholder='Descripcion'
                value={descripcion}
                required
                onChange={(e) => {
                  setDescripcion(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className='mr-sm-2'>{t('Moneda')}</Label>
              <Input
                // bsSize="sm"
                type='select'
                required
                value={moneda}
                onChange={(e) => {
                  setMoneda(e.target.value);
                  setTotalUSD(0);
                  setTotal(0);
                  setTipoCambio(1);
                }}
              >
                <option value='MXN'>{t('MXN')}</option>
                <option value='USD'>{t('USD')}</option>
              </Input>
            </Col>
          </Row>
          {moneda === 'USD' ? (
            <Row>
              <Col sm={4}>
                <Label for='exampleEmail' className='mr-sm-2'>
                  {t('Total USD')}
                </Label>
                <Input
                  type='number'
                  min='0'
                  step='any'
                  placeholder='TotalUSD'
                  value={totalUSD}
                  required
                  onChange={(e) => calcula(e.target.value)}
                />
              </Col>
              <Col sm={4}>
                <Label for='exampleEmail' className='mr-sm-2'>
                  Tipo de Cambio
                </Label>
                <Input
                  type='number'
                  min='0'
                  step='any'
                  placeholder='TipoCambio'
                  value={tipoCambio}
                  required
                  onChange={(e) => calculaTipoCambio(e.target.value)}
                />
              </Col>
              <Col sm={4}>
                <Label for='exampleEmail' className='mr-sm-2'>
                  {t('Total MXN')}
                </Label>
                <Input
                  type='number'
                  min='0'
                  step='any'
                  value={total}
                  disabled
                  // onChange={(e) => calculaMXN(e.target.value)}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={4}>
                <Label for='exampleEmail' className='mr-sm-2'>
                  {t('Total MXN')}
                </Label>
                <Input
                  type='number'
                  min='0'
                  step='any'
                  value={total}
                  onChange={(e) => {
                    setTotal(e.target.value);
                    setTotalUSD(e.target.value / tipoCambio);
                  }}
                />
              </Col>
            </Row>
          )}
          <br />
          {validaBoton ? (
            <Button color='success' onClick={() => saveCargo()}>
              Guardar
            </Button>
          ) : (
            <Button color='success' disabled>
              Guardar
            </Button>
          )}
        </ModalBody>
      </Modal>

      <Modal size='sm' isOpen={modalETA} toggle={toggleETA}>
        <ModalHeader toggle={toggleETA}>
          <h4>{t('Actualizar ETAs')}</h4>
        </ModalHeader>
        <ModalBody>
          <EtaUpdate />
        </ModalBody>
      </Modal>

      <Modal size='xl' isOpen={modalMapa} toggle={toggleMapa}>
        <ModalHeader toggle={toggleMapa}>
        <h4>Trayectoria del Contenedor. Faltan {diasLlegada} dias para la llegada</h4>
          </ModalHeader>
      <ModalBody>
      <MapContainer 
          center={vesselCoords}  // Cambia el centro al océano Pacífico, lo que coloca Asia a la izquierda y América a la derecha
          zoom={3} 
          style={{ height: "400px", width: "100%" }}
          maxBounds={[[85, -180], [-85, 180]]}  // Limita el mapa a un único globo terráqueo
          maxBoundsViscosity={1.0}  // Impide que se salga de los límites
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            noWrap={true}  // Evita que el mapa se "envuelva"
          />

          {/* Marcadores para el origen, posición actual y destino */}
          <Marker position={destinationCoords}></Marker>
          <Marker position={vesselCoords}></Marker>
          <Marker position={originCoords}></Marker>

          {/* Línea de trayectoria */}
          {/* <Polyline positions={coordenadasMapa} color="blue" />
           */}
           <Polyline positions={[vesselCoords, destinationCoords]} color="blue" dashArray="5,10" />
        </MapContainer>
      </ModalBody>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoContenedores;
